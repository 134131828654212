import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FirebaseMessaging,
  GetTokenOptions,
  PermissionStatus,
} from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { environment } from 'src/environments/environment';
import { Device } from '@capacitor/device';
import { Device as DeviceModel } from 'src/app/_models/device';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private token!: string;
  protected device!: DeviceModel;
  public permissionStatus$ = new BehaviorSubject<string>('prompt');
  public token$ = new BehaviorSubject<string | null>(null);

  constructor(
    private router: Router
  ) { }

  initializeFirebaseMessaging() {
    console.log("Initializing Firebase Messaging...");
    if (!this.isNotificationSupported()) {
      console.log('Push notifications are not supported in this browser.');
      return;
    }
    this.setupFirebaseListeners();
  }

  private isNotificationSupported() {
    return 'Notification' in window && 'serviceWorker' in navigator;
  }

  private setupFirebaseListeners() {
    console.log("Setting up Firebase listeners...");  
    FirebaseMessaging.addListener("notificationReceived", (event) => {
      console.log("notificationReceived: ", { event });
    });

    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log("notificationActionPerformed: ", { event });
    });

    if (Capacitor.getPlatform() === "web") {
      this.setupWebServiceWorker();
    }
  }

  private setupWebServiceWorker() {
    navigator.serviceWorker.addEventListener("message", (event: any) => {
      
      let notificationPayload = this.getNotificationPayload(event);
      let notificationData = event.data.data || null;

      if(notificationPayload) {
        this.showNotification(notificationPayload, notificationData);
      }
    });
  }

  private getNotificationPayload(event: any) {
    return event.data.notification || (event.data.data && event.data.data.notification) || null;
  }

  private showNotification(payload: any, data: any) {
    const notification = new Notification(payload?.title, {
      body: payload?.body,
      icon: payload?.icon,
      badge: payload?.badge,
      data: data,
    });

    notification.onclick = (event: any) => {
      const notificationReceived: Notification = event.target;
      if(notificationReceived.data?.click_action) {
        this.router.navigate([notificationReceived.data?.click_action]);
      }
    }
  }

  public async checkPermissions(): Promise<void> {
    try {
      let permissionStatus: PermissionStatus = await FirebaseMessaging.checkPermissions();

      if (permissionStatus.receive === "prompt") {
        await FirebaseMessaging.requestPermissions();
        permissionStatus = await FirebaseMessaging.checkPermissions();
       
        if(permissionStatus.receive === "granted") {
          this.getToken();
        }
      }
      this.permissionStatus$.next(permissionStatus.receive);
    } catch (error) {
      console.error(error);
    }
  }

  public async getToken(): Promise<void> {
    try {
      const options: GetTokenOptions = {
        vapidKey: environment.firebase.vapidKey,
        serviceWorkerRegistration: await navigator.serviceWorker.register("firebase-messaging-sw.js"),
      };

      const { token } = await FirebaseMessaging.getToken(options);
      this.token$.next(token);
    } catch (error) {
      console.error(error);
    }
  }
}
