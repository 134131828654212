import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap} from 'rxjs/operators';
import { AccountService } from '../_services/account.service';

@Injectable({
  providedIn: 'root'
})
export class AutologinGuard implements CanLoad {
  constructor(
    private router: Router,
    private accountService: AccountService
  ) {}

  canLoad(): Observable<boolean> {
    return this.accountService.initDone.pipe(
			filter(done => done === true),
        switchMap(() => {
          return this.accountService.userValue.pipe(
			      map((user) => {
				      if (user) {
                this.router.navigateByUrl('/pwa/main/home', { replaceUrl: true});
                return false;
              }
              return true;
			      })
          );
        }
      )
    );
  }
}
